
/*@font-face {*/
/*    font-family: 'PromptLight';*/
/*    src: local('PromptLight'), url(fonts/Prompt/Prompt-Light.ttf) format('ttf');*/
/*    !* other formats include: 'woff2', 'truetype, 'opentype',*/
/*                              'embedded-opentype', and 'svg' *!*/
/*}*/
@font-face {
    font-family: 'PromptLight';
    src: local('PromptLight'), url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Prompt:wght@300&display=swap) format('ttf');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}


.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(155, 184, 200, 100);
    border-left: 1.1em solid #CD5F40;
    /*border-left: 1.1em solid #9BB8C8;*/
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader--hide {
    opacity: 0;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.right-ocean{
    position: absolute;
    right: 0;
}


/*p{*/
/*    font-family:'PromptLight'!important;*/
/*}*/
ul {
    list-style-type: none;
}
.App {
    text-align: center;
}
.footer{
    background-color: #CD5F40;
    /*position:fixed;*/
    padding-top:30px;
    padding-bottom:30px;
}
.footer p{
    color: #FAFAFA;
    /*font-family:'PromptLight'!important;*/
    font-size: 18px;
}
.footer hr{
    color: #FAFAFA!important;
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid;
    opacity: 1.25!important;
}
.footer h1{
    color: #FAFAFA;
    font-size: 54px;
}
.footer a {
    font-size: 14px!important;
}
.footer ul,li{
    font-size: 12px;
    color:  #FAFAFA;
    /*font-family:'Prompt-Light';*/
}
a {
    color: inherit !important;
    text-decoration: none !important;
}
/*.link-nav{*/
/*    position: fixed;*/
/*    right: 0;*/
/*    left: 0;*/
/*    z-index: 1030;*/
/*}*/
.nav-menu{
    position: fixed!important;
    right: 0!important;
    left: 0!important;
    z-index: 1030!important;
    /*background-color: rgba(1, 0, 0, 0.1) !important;*/

}
.limite{
    object-fit: cover;
    /*width: 100%;*/
    height: 90vh!important;
    /*background-size: 100% 80vh;*/
}

.img-class{
  /*margin-top: -28px;*/
    /*height: 100vh;*/
    /*background-position: center;*/
    width: 100%;
}
.nav-menu{
    /*padding-top: 50px;*/
    text-align: left!important;


}
.section-menu{
    display: none;


}
.new-class-menu{
    position: fixed;
    width: 100%;
    z-index: 90;

}
#id-menu{
    display: none;
}
.hide-class{
    padding-top: 50px;

}

.im-number-section{
    /*width: 300px;*/
    float: left;

}
.im-number-sectionr{
    /*width: 30px;*/
    float: right;

}
.hide-class p{
    float: left;
    color: #FAFAFA;
    font-size: 18px;
}
.section-menu h1{
    color: #FAFAFA;
}


.numero{
    color: #FAFAFA!important;
    top: 2%!important;
    left: 8% !important;
}
.direita{
    text-align: right!important;
    /*top: 2%!important;*/
    /*right: 8% !important;*/
    /*margin-left: 230%;*/
}
.ocean-section{
    position: relative;
    padding-top: 30px;
    background: #FAFAFA;
    padding-bottom: 30px;
}
.ocean-section h1{
    color: #9BB8C8;
    font-size: 54px;
}
.ocean-section p{
    color: #777777;
    font-size: 18px;
}
.ocean-section h3{
    color: #9BB8C8;
    font-size: 48px;
}
.vm{
    padding-top: 20px;
    color: #CD5F40!important;
    font-size: 18px;
    font-weight: bold;
}
.carousel-dark .carousel-indicators [data-bs-target] {
    display: none!important;


}
#carosel{
    background-color:#9BB8C8 ;
}
.selection-section{
    padding-top: 30px;
    padding-bottom: 30px;
    background: #9BB8C8;
}
.selection-section img{
    width: 377.95px;
    /*align-content: center;*/
    height: auto;
}
.selection-section h3{
    padding-top: 30px;
    font-size: 54px;
    color: #FFFFFF;
}
.selection-section  p{
    /*font-family:'Prompt-Light';*/
    color: #FFFFFF;
    font-size: 18px;
}
.arrow{
    /*margin-top: 10%;*/
    margin-top: -70px;
    /*width: 40px;*/
    /*height: auto;*/
}
.scrollable-place {
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
}
button{
    color: #FAFAFA;
}
.unstyled-button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}
 .unstyled-button:hover {
    /*background-color: #CD5F40;*/
    color: #CD5F40;
}
.active{
    color: #CD5F40;
}
.inactive{

}


.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
.hide-image{
    display: none;
}
.logo{
    width: 38%;
}
.camara{
    width: 377.95px;
}
.banner{
    background-image:url("../assets/img/ocean_main_banner.png") ;
    height: 95vh;
    background-repeat: no-repeat;
    background-size: 100% 95vh;
    background-position: center;
}
.image-numero{
    width: 288px;
}
.image-menu{
    width: 200px;
}
#image-menu-x{
    display: none;
}

@media  (max-width: 450px) {
    .scrollable-place {
        height: 100%;
    }
    body{
        width: 100% !important;
    }
    button{
        display: none!important;
    }
    .logo{
        width: 323px;
    }
    .camara{
        width: 323px!important;
    }
    .banner{
        background-image:url("../assets/img/ocean_main_banner.png") ;
        height: 95vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .image-menu{
        width: 40%!important;
    }
    .image-numero{
        width: 40% !important;
    }
    .direita{
        margin-top: -31px;
        text-align: right!important;
        /*top: 2%!important;*/
        /*right: 8% !important;*/
        margin-left: 47%;
        width: 54%;
    }
    .numero {
        color: #FAFAFA!important;
        top: 2%!important;
        left: 8% !important;
        font-size: 6px!important;
    }
    .navbar-toggler-icon{
        display: none!important;
    }
}
@media (min-width: 481px)  and (max-width: 930px) {
    .direita{
        margin-top: -70px;
        text-align: right!important;
        /*top: 2%!important;*/
        /*right: 8% !important;*/
        /*margin-left: 50%;*/
    }
    .scrollable-place {
        height: 100%;
    }
    body{
        width: 100% !important;
    }
    button{
        display: none!important;
    }
    .logo{
        width: 323px;
    }
    .camara{
        width: 323px!important;
    }
    .banner{
        background-image:url("../assets/img/ocean_main_banner.png") ;
        height: 95vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .image-menu{
        width: 40%!important;
    }
    .image-numero{
        width: 40% !important;
    }
    .numero {
        color: #FAFAFA!important;
        top: 2%!important;
        left: 8% !important;
        font-size: 6px!important;
    }
    .navbar-toggler-icon{
        display: none!important;
    }
}